// export const API_BASE_URL = 'http://localhost:8000';
// export const IMAGES_BASE_URL= 'http://localhost:8000/'
// //export const IMAGES_BASE_URL= 'http://127.0.0.1/gdtc/gdtc_back'
// export const BASE_URL ='http://localhost:3000';

// export const API_BASE_URL = 'https://gdtc.ac/test-45858225';
// export const IMAGES_BASE_URL= 'https://gdtc.ac/test-45858225'
// export const BASE_URL ='https://gdtc.ac/test-45858225';
export const API_BASE_URL = 'https://gdtc.ac/842508';
export const IMAGES_BASE_URL = 'https://gdtc.ac/842508';
export const BASE_URL = 'https://gdtc.ac/test-45858225';
export const MEDIA_URL = 'https://media.gdtc.ac/';
export const TEACH_URL = 'https://teachable.gdtc.ac/'
export const TEMP_URL = '/test-5436726732/';

export const professions = [{ id: 1, label: "docteur" }, { id: 2, label: "engineer" }, { id: 3, label: "Formateur" }, { id: 4, label: "ingénieur informatique " }];

export const consol = (v1, v2 = null, v3 = null) => {
  console.log(v1);
  if (v2) console.log(v2);
  if (v3) console.log(v3);
}
//#26bbcd
export const primaryColor = (pathname) => {
  return pathname.includes('medical-center') ? '#22427c' : '#26bbcd';
};
export const secondColor = (pathname) => {
  return pathname.includes('medical-center') ? '#D4D3DC' : '#c4dcdb';
};
export const thirdColor = (pathname) => {
  return pathname.includes('medical-center') ? 'blue' : 'green';
};